import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Table from '../../components/common/Table';
import axios from 'axios';
import swal from 'sweetalert2';

const Container = styled.div`
  background-color: white;
  height: 94vh;
  overflow: scroll;
  width: 100%;
  margin: 20px 20px 20px 0px;
  padding: 20px;
  box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
const Button = styled.button`
  display: inline-block;
  width: 80px;
  height: 30px;
  background-color: #eeeeee;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border: none;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  :hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
`;
const CategoryDetailPage = () => {
  const [sub, setSub] = useState();
  const { name } = useParams();

  const getCategory = async () => {
    try {
      let { data: res } = await axios.get(`/admin/category/${name}`);
      if (res.length !== 0) {
        res.map((r) => {
          const id = r.subId;
          delete r.subId;
          r.modify = <Button onClick={() => _handleModify(id)}>변경</Button>;
          r.delete = <Button onClick={() => _handleDelete(id)}>삭제</Button>;
          return r;
        });
        setSub(res);
      } else {
        setSub(null);
      }
    } catch (e) {
      console.log(e, 'getList');
    }
  };

  const _handleModify = async (id) => {
    const { value: name } = await swal.fire({
      title: '변경할 이름을 입력해 주세요.',
      input: 'text',
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      confirmButtonColor: '#232323',
      cancelButtonColor: '#a3a3a3',
      inputValue: '',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return '입력된 값이 없습니다.';
        }
      },
    });
    if (name) {
      try {
        const res = await axios.patch(`/admin/category/update/${id}`, {
          sub_category: name,
        });
        if (res.status === 200) {
          swal.fire({
            text: '변경이 완료되었습니다.',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000,
          });
          getCategory();
        }
      } catch (e) {
        swal.fire({
          width: 400,
          text: '오류가 발생했습니다.',
          icon: 'error',
          showConfirmButton: false,
          timer: 1000,
        });
      }
    }
  };
  const _handleDelete = (id) => {
    swal
      .fire({
        title: '정말 카테고리를 삭제하시겠습니까?',
        text: '해당 카테고리의 모든 제품은 삭제됩니다.',
        icon: 'warning',
        iconColor: '#d33',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#a8a8a8',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const res = await axios.delete(`/admin/category/delete/${id}`);
            if (res.status === 200) {
              swal.fire({
                text: '제품 삭제가 완료되었습니다.',
                icon: 'success',
                showConfirmButton: false,
                timer: 1000,
              });
              getCategory();
            }
          } catch (e) {
            swal.fire({
              position: 'top-end',
              width: 400,
              text: '오류가 발생했습니다.',
              icon: 'error',
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
  };
  const _handleCreate = async () => {
    const value = await swal.fire({
      title: '추가할 카테고리를 입력해 주세요.',
      input: 'text',
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      confirmButtonColor: '#232323',
      cancelButtonColor: '#a3a3a3',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return '입력된 값이 없습니다.';
        }
      },
    });
    if (value) {
      try {
        const res = await axios.post(`/admin/category/${name}`, {
          sub_category: value.value,
        });
        if (res.status === 200) {
          swal.fire({
            text: '성공적으로 추가되었습니다.',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000,
          });
          getCategory();
        }
      } catch (e) {
        swal.fire({
          width: 400,
          text: '오류가 발생했습니다.',
          icon: 'error',
          showConfirmButton: false,
          timer: 1000,
        });
      }
    }
  };

  const titles = ['카테고리', '이름 변경', '카테고리 삭제'];

  useEffect(() => {
    getCategory();
  }, []);

  const button = (
    <Button
      style={{ width: '80px', height: '40px', backgroundColor: '#a3a3a3' }}
      onClick={() => _handleCreate()}
    >
      추가
    </Button>
  );

  return (
    <Container>
      {sub ? (
        <Table
          caption="카테고리 조회"
          titles={titles}
          data={sub}
          button={button}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h2>
            해당 제조사에 카테고리가 존재하지 않습니다. 새로 생성하시겠습니까?
          </h2>
          <Button onClick={() => _handleCreate()}>생성</Button>
        </div>
      )}
    </Container>
  );
};

export default CategoryDetailPage;
