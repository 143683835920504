/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Table from '../../components/common/Table';
import SmallButton from '../../components/common/SmallButton';
import axios from 'axios';
import styled from 'styled-components';
import Loading from '../../components/common/Loading';

const Container = styled.div`
  background-color: white;
  height: 94vh;
  overflow: scroll;
  width: 100%;
  margin: 20px 20px 20px 0px;
  padding: 20px;
  box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const ProductListPage = () => {
  const [products, setProducts] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const getProducts = async () => {
    try {
      setIsLoading(true);
      let { data: res } = await axios.get('/admin/products/productlist');
      res.map((r) => {
        const url = '/product/list/detail/' + r._id;
        delete r._id;
        delete r.level1;
        delete r.level2;
        delete r.level3;
        delete r.vip;
        delete r.school;
        delete r.mall;
        delete r.description;
        delete r.img;
        r.detail = <SmallButton text="보기" url={url} />;
        setIsLoading(false);
        return r;
      });
      setProducts(res);
    } catch (e) {
      console.log(e, 'getList');
    }
  };

  const titles = ['제조사', '카테고리', '제품명', '상세 보기'];

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Container>
      {products && (
        <Table caption="제품 조회" titles={titles} data={products} />
      )}
      {isLoading && <Loading />}
    </Container>
  );
};

export default ProductListPage;
