import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Button = styled.button`
  display: inline-block;
  width: 80px;
  height: 40px;
  background-color: #eeeeee;
  margin-left: 10px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border: none;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  :hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
`;

const TableContainer = styled.table`
  border: none;
  border-collapse: collapse;
  td,
  th {
    text-align: center;
    border: none;
    padding: 5px 10px;
  }
  tbody tr {
    height: 50px;
  }
  thead > tr {
    background-color: #eeeeee;
    height: 50px;
  }
`;

const Table = ({ caption, titles, data, button }) => {
  const titlesIdx = Object.keys(data[0]);
  return (
    <Container>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2>{caption}</h2>
        {button}
      </div>
      <TableContainer>
        <thead>
          <tr>
            {titles.map((title, index) => {
              return <th key={index}>{title}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              {titlesIdx.map((title, index) => {
                if (title !== '_id') return <td key={index}>{item[title]}</td>;
                else return null;
              })}
            </tr>
          ))}
        </tbody>
      </TableContainer>
    </Container>
  );
};

export default Table;
