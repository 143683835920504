import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../components/common/Button';
import Input from '../components/common/Input';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebaseInstance';

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoginPage = ({ onLogin }) => {
  const [inputs, setInputs] = useState({
    email: '',
    password: '',
  });
  const { email, password } = inputs;

  const onChange = (e) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const _handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        localStorage.setItem('user', JSON.stringify(user));
        onLogin();
      })
      .catch((error) => {
        const errorMessage = error.message;
        alert(errorMessage);
        // ..
      });
  };

  return (
    <Container>
      <h1 style={{ margin: '0px' }}>SUPERTRACK</h1>
      <h2 style={{ margin: '0 0 100px 0' }}>PARTNERS ADMIN</h2>
      <Input
        type="email"
        name="email"
        value={email}
        onChange={onChange}
        placeholder="EMAIL"
      />
      <Input
        type="password"
        name="password"
        value={password}
        onChange={onChange}
        placeholder="PASSWORD"
      />
      <Button
        width="400"
        onClick={() => _handleLogin()}
        style={{ backgroundColor: 'black', color: 'white' }}
      >
        LOGIN
      </Button>
    </Container>
  );
};

export default LoginPage;
