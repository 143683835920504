import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import { BiUserCircle, BiBox, BiImport } from 'react-icons/bi';
import { updatePassword } from 'firebase/auth';
import { auth } from '../../firebaseInstance';
import swal from 'sweetalert2';
import axios from 'axios';

const Container = styled.div`
  z-index: 100;
  height: 94vh;
  width: 200px;
  margin: 20px;
  padding: 20px;
  background: white;
  box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
`;
const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
`;

const Menu = styled.div`
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0 10px 0;
`;

const IconBox = styled.div`
  width: 35px;
  height: 35px;
  background: #eeeeee;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const Submenu = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #8a8a8a;
  margin: 5px 0 5px 10px;
  align-self: flex-start;
  cursor: pointer;
  :hover {
    color: black;
  }
`;

const Sidebar = ({ onLogout }) => {
  const navigate = useNavigate();
  const _handlePassword = async () => {
    const user = auth.currentUser;
    const { value: pwd } = await swal.fire({
      title: '새로운 비밀번호를 입력해 주세요.',
      text: '변경 후에는 새로운 비밀번호로 재로그인이 필요합니다.',
      input: 'password',
      inputPlaceholder: '6자리 이상 입력',
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      confirmButtonColor: '#232323',
      cancelButtonColor: '#a3a3a3',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return '입력된 값이 없습니다.';
        }
      },
    });
    if (pwd) {
      updatePassword(user, pwd)
        .then(() => {
          swal
            .fire({
              title: '변경이 완료되었습니다.',
              text: '재로그인을 위해 로그아웃됩니다.',
              icon: 'success',
              showConfirmButton: true,
              confirmButtonColor: '#232323',
            })
            .then(async (result) => {
              if (result.isConfirmed) {
                try {
                  localStorage.removeItem('user');
                  window.location.reload();
                } catch (e) {
                  swal.fire({
                    position: 'top-end',
                    width: 400,
                    text: '오류가 발생했습니다.',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1000,
                  });
                }
              }
            });
        })
        .catch((error) => {
          console.log(error);
          swal.fire({
            width: 400,
            title: '오류가 발생했습니다.',
            text: error,
            icon: 'error',
            showConfirmButton: false,
            timer: 1000,
          });
        });
    }
  };
  const _handleShipping = async () => {
    const { data: prev } = await axios.get(
      `/admin/shipping/price`,
    );
    const { value: shipping } = await swal.fire({
      title: '새로운 배송비값을 입력해 주세요.',
      text: `현재 배송비는 ${prev}원입니다.`,
      input: 'number',
      inputPlaceholder: '숫자만 입력',
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      confirmButtonColor: '#232323',
      cancelButtonColor: '#a3a3a3',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return '입력된 값이 없습니다.';
        }
      },
    });
    if (shipping) {
      await axios.patch(`/admin/shipping/price/${shipping}`)
        .then(() => {
          swal
            .fire({
              title: '변경이 완료되었습니다.',
              text: '이후 주문건부터 새로운 값이 적용됩니다.',
              icon: 'success',
              showConfirmButton: true,
              confirmButtonColor: '#232323',
            })
        })
        .catch((error) => {
          console.log(error);
          swal.fire({
            width: 400,
            title: '오류가 발생했습니다.',
            text: error,
            icon: 'error',
            showConfirmButton: false,
            timer: 1000,
          });
        });
    }
  };

  return (
    <Container>
      <Top>
        <MenuContainer>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <MenuWrapper>
              <Menu>
                <IconBox>
                  <BiImport />
                </IconBox>
                주문 관리
              </Menu>
              <Submenu onClick={() => navigate('/order/list')}>
                ‣ 주문 목록
              </Submenu>
            </MenuWrapper>
            <MenuWrapper>
              <Menu>
                <IconBox>
                  <BiUserCircle />
                </IconBox>
                회원 관리
              </Menu>
              <Submenu onClick={() => navigate('/user/list')}>
                ‣ 회원 목록
              </Submenu>
            </MenuWrapper>
            <MenuWrapper>
              <Menu>
                <IconBox>
                  <BiBox />
                </IconBox>
                제품 관리
              </Menu>
              <Submenu onClick={() => navigate('/product/list')}>
                ‣ 제품 조회
              </Submenu>
              <Submenu onClick={() => navigate('/product/add')}>
                ‣ 제품 등록
              </Submenu>
              <Submenu onClick={() => navigate('/product/category')}>
                ‣ 제품 분류
              </Submenu>
              <Submenu onClick={() => navigate('/product/total')}>
                ‣ 전체 제품 가격표
              </Submenu>
            </MenuWrapper>
            <MenuWrapper>
              <Menu>
                <IconBox>
                  <BiUserCircle />
                </IconBox>
                어드민 관리
              </Menu>
              <Submenu onClick={() => navigate('/admin/main')}>
                ‣ 메인 이미지 변경
              </Submenu>
              <Submenu onClick={() => navigate('/admin/notice')}>
                ‣ 공지 이미지 변경
              </Submenu>
              <Submenu onClick={() => _handleShipping()}>
                ‣ 배송비 변경
              </Submenu>
              <Submenu onClick={() => _handlePassword()}>
                ‣ 비밀번호 변경
              </Submenu>
            </MenuWrapper>
          </div>
        </MenuContainer>
      </Top>
      <div>
        <Button width="160" onClick={onLogout}>
          LOGOUT
        </Button>
      </div>
    </Container>
  );
};

export default Sidebar;
