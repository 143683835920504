/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Table from '../../components/common/Table';
import download from 'downloadjs';
import swal from 'sweetalert2';
import Loading from '../../components/common/Loading';
import Pagination from 'react-bootstrap/Pagination';

const Container = styled.div`
  background-color: white;
  height: 94vh;
  overflow: scroll;
  width: 100%;
  margin: 20px 20px 20px 0px;
  padding: 20px;
  box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Text = styled.div`
  font-size: 14px;
  margin-right: 30px;
  /* width: 240px; */
  justify-content: center;
`;

const DownloadButton = styled.button`
  display: inline-block;
  width: 80px;
  height: 30px;
  background-color: #eeeeee;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border: none;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  :hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
`;

const OrderListPage = () => {
  const [orders, setOrders] = useState();
  const [temp, setTemp] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState();
  const [pagination, setPagination] = useState(0);

  const _handlePageChange = (pageNumber) => {
    if (0 < pageNumber && pageNumber < limit + 1) {
      setPage(pageNumber);
      getOrder(pageNumber);
      if (parseInt((pageNumber - 1) / 10) !== pagination) {
        setPagination(parseInt((pageNumber - 1) / 10));
      }
    }
  }

  const updatePostNum = async ({ id, num }) => {
    if (num === '발주 확인 중') num = '';
    const { value: postNum } = await swal.fire({
      title: '운송장 번호를 입력해 주세요.',
      input: 'text',
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      confirmButtonColor: '#232323',
      cancelButtonColor: '#a3a3a3',
      inputValue: num,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return '입력된 값이 없습니다.';
        }
      },
    });
    if (postNum) {
      try {
        const res = await axios.patch(`/admin/users/orderlist/${id}`, {
          postNum: postNum,
        });
        if (res.status === 200) {
          swal.fire({
            text: '변경이 완료되었습니다.',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000,
          });
          getOrder();
        }
      } catch (e) {
        swal.fire({
          width: 400,
          text: '오류가 발생했습니다.',
          icon: 'error',
          showConfirmButton: false,
          timer: 1000,
        });
      }
    }
  };

  const getLimit = async () => {
    const { data: res } = await axios.get(`/admin/users/orderlist/1`);
    setLimit(res.limit);
  }

  const makeArray = () => {
    let arr = [];
    let num = 0;
    if (parseInt(limit / 10) === pagination) {
      num = pagination * 10 + limit % 10 + 1
    } else {
      num = pagination * 10 + 11
    }
    for (let i = pagination * 10 + 1; i < num; i++) {
      arr.push(<Pagination.Item active={page === i} onClick={() => _handlePageChange(i)}>{i}</Pagination.Item>);
    }
    return arr;
  }

  const getOrder = async (num) => {
    try {
      setIsLoading(true);
      const { data: res } = await axios.get(`/admin/users/orderlist/${num}`);      
      res.data.map((r, i) => {
        const num = r.postNum;
        r.postNum = (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text>{r.postNum}</Text>
            <DownloadButton
              onClick={() => updatePostNum({ id: res.id[i].id, num: num })}
              style={{ width: '50px' }}
            >
              수정
            </DownloadButton>
          </div>
        );
        r.button = (
          <DownloadButton onClick={() => getUrl(r.orderId, res.id[i].id)}>
            다운로드
          </DownloadButton>
        );
        setIsLoading(false);
        return r;
      });
      setOrders(res.data);
    
    } catch (e) {
      console.log(e);
    }
  };

  const getUrl = async (orderId, id) => {
    try {
      const { data: res } = await axios.get(
        `/admin/users/orderlist/download/${id}`,
      );
      download(res, `슈퍼트랙_발주서_${orderId}.pdf`, 'application/pdf');
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getOrder();
    getLimit();
  }, []);

  const titles = ['발주번호', '회사', '발주날짜', '운송장 번호', '발주서'];

  return (
    <Container>
      { orders ? (
        <>
          <Table caption="발주 내역" titles={titles} data={orders} />
          <Pagination >
            <Pagination.First style={{ color: 'black' }} onClick={() => _handlePageChange(1)} />
            <Pagination.Prev onClick={() => _handlePageChange(page - 1)} />
            {makeArray()}
            <Pagination.Next onClick={() => _handlePageChange(page + 1)} />
            <Pagination.Last onClick={() => _handlePageChange(limit)} />
          </Pagination>
        </>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p>발주 내역이 없습니다.</p>
        </div>
      )}
      {isLoading && <Loading />}
    </Container>
  );
};

export default OrderListPage;
