import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: white;
  height: 94vh;
  overflow: scroll;
  width: 100%;
  margin: 20px 20px 20px 0px;
  padding: 20px;
  box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const UserLevelPage = () => {
  return <Container>회원 등급 관리 개발 예정</Container>;
};

export default UserLevelPage;
