/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Table from '../../components/common/Table';
import LevelDropdown from '../../components/common/LevelDropdown';
import axios from 'axios';
import swal from 'sweetalert2';
import Loading from '../../components/common/Loading';

const TrueButton = styled.button`
  display: inline-block;
  width: 60px;
  height: 35px;
  background: #eeeeee;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border: none;
  color: #164db5;
  font-size: 15px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  :hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
`;

const FalseButton = styled.button`
  display: inline-block;
  width: 60px;
  height: 35px;
  background: #eeeeee;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border: none;
  color: #db3a34;
  font-size: 15px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  :hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
`;

const Container = styled.div`
  background-color: white;
  height: 94vh;
  overflow: scroll;
  width: 100%;
  margin: 20px 20px 20px 0px;
  padding: 20px;
  box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const UserListPage = () => {
  const [users, setUsers] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const getUsers = async () => {
    try {
      setIsLoading(true);
      let res = await axios.get('/admin/users/userlist');
      res.data.map((r) => {
        if (r.approval === true) {
          r.approval = (
            <TrueButton onClick={() => _handleDelete(r)}>승인</TrueButton>
          );
          r.level = (
            <LevelDropdown
              user={r}
              level={r.level}
              handleLevel={_handleLevel}
              approved={true}
            />
          );
        } else {
          r.approval = (
            <FalseButton onClick={() => _handleApprove(r)}>대기</FalseButton>
          );
          r.level = <LevelDropdown user={r} level={'설정'} approved={false} />;
        }
        r.reset = (
          <FalseButton onClick={() => _handleReset(r)}>리셋</FalseButton>
        );
        setIsLoading(false);
        return r;
      });
      res.data.reverse();
      setUsers(res.data);
    } catch (e) {
      console.log(e, 'getUsers');
    }
  };

  const _handleApprove = async (user) => {
    swal
      .fire({
        text: '가입을 승인하시겠습니까?',
        icon: 'warning',
        iconColor: '#d33',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#a8a8a8',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const res = await axios.patch(`/admin/users/update/${user._id}`);
            if (res.status === 200) {
              swal.fire({
                text: '승인이 완료되었습니다.',
                icon: 'success',
                showConfirmButton: false,
                timer: 1000,
              });
              getUsers();
            }
          } catch (e) {
            swal.fire({
              width: 400,
              text: '오류가 발생했습니다.',
              icon: 'error',
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
  };

  const _handleDelete = async (user) => {
    swal
      .fire({
        text: '승인을 취소하시겠습니까?',
        icon: 'warning',
        iconColor: '#d33',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#a8a8a8',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const res = await axios.patch(`/admin/users/delete/${user._id}`);
            if (res.status === 200) {
              swal.fire({
                text: '승인 취소가 완료되었습니다.',
                icon: 'success',
                showConfirmButton: false,
                timer: 1000,
              });
              getUsers();
            }
          } catch (e) {
            swal.fire({
              width: 400,
              text: '오류가 발생했습니다.',
              icon: 'error',
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
  };

  const _handleReset = async (user) => {
    swal
      .fire({
        text: '유저의 비밀번호를 [000000]으로 리셋하시겠습니까?',
        icon: 'warning',
        iconColor: '#d33',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#a8a8a8',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const res = await axios.patch(`/admin/users/password/${user._id}`);
            if (res.status === 200) {
              swal.fire({
                text: '리셋이 완료되었습니다.',
                icon: 'success',
                showConfirmButton: false,
                timer: 1000,
              });
              getUsers();
            }
          } catch (e) {
            swal.fire({
              width: 400,
              text: '오류가 발생했습니다.',
              icon: 'error',
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
  };

  const _handleLevel = async ({ level, user }) => {
    swal
      .fire({
        text: '등급을 변경하시겠습니까?',
        icon: 'warning',
        iconColor: '#d33',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#a8a8a8',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const res = await axios.patch(
              `/admin/users/update/${user._id}/${level}`,
            );
            if (res.status === 200) {
              swal.fire({
                text: '등급이 변경 완료되었습니다.',
                icon: 'success',
                showConfirmButton: false,
                timer: 1000,
              });
              getUsers();
            }
          } catch (e) {
            swal.fire({
              width: 400,
              text: '오류가 발생했습니다.',
              icon: 'error',
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
  };

  const titles = [
    '이메일',
    '담당자 이름',
    '담당자 연락처',
    '회사명',
    '등급',
    '승인 여부',
    '비밀번호 리셋'
  ];

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Container>
      {users && <Table caption="회원 목록" titles={titles} data={users} />}
      {isLoading && <Loading />}
    </Container>
  );
};

export default UserListPage;
