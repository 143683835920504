/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import swal from 'sweetalert2';
import { storage } from '../../firebaseInstance';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const Container = styled.div`
  height: 94vh;
  overflow: scroll;
  width: 100%;
  margin: 20px 20px 20px 0px;
  padding: 0px 20px 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Button = styled.button`
  display: inline-block;
  width: 100px;
  height: 40px;
  background-color: #a8a8a8;
  margin-left: 10px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border: none;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  :hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const Box = styled.div`
  background-color: white;
  min-width: 500px;
  min-height: 500px;
  padding: 5px 20px 20px 20px;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.02);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ThumbnailContainer = styled.label`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
  border-radius: 20px;
  object-fit: cover;
  position: absolute;
`;

const Hover = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 20px;
  z-index: 100;
  position: absolute;
  :hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const MainImage = () => {
  const [url, setUrl] = useState();
  const [file, setFile] = useState();

  useEffect(() => {
    getImage();
  }, []);

  const getImage = () => {
    getDownloadURL(ref(storage, 'admin/mainimage'))
      .then((url) => {
        setUrl(url);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const _uploadImage = async (e) => {
    const imgFile = e.target.files[0];
    const url = URL.createObjectURL(imgFile);
    setUrl(url);
    setFile(imgFile);
  };

  const changeImage = async () => {
    swal
      .fire({
        text: '사진을 변경하시겠습니까?',
        icon: 'warning',
        iconColor: '#d33',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#a8a8a8',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const imageRef = ref(storage, `/admin/mainimage`);
          uploadBytes(imageRef, file);
          try {
            const resImg = await axios.get(
              `/admin/image/main`,
            );
            if (resImg.status === 200) {
              swal.fire({
                text: '이미지 변경이 완료되었습니다.',
                icon: 'success',
                showConfirmButton: false,
                timer: 1000,
              });
            }
          } catch (e) {
            swal.fire({
              width: 400,
              text: '오류가 발생했습니다.',
              icon: 'error',
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
  };

  return (
    <div style={{ width: '100%' }}>
      <Container>
        <RowWrapper>
          <RowWrapper style={{ alignItems: 'flex-end' }}>
            <h2>메인 이미지 변경</h2>
            <h4 style={{ marginLeft: '10px', color: '#a8a8a8' }}>
              사진 수정 후 등록 버튼을 꼭 눌러주세요.
            </h4>
          </RowWrapper>
          <Button onClick={() => changeImage()}>등록</Button>
        </RowWrapper>
        <Box>
          <h3>이미지</h3>
          <ThumbnailContainer for="input-file">
            <Hover></Hover>
            <Image id="image" src={url} />
          </ThumbnailContainer>
          <input
            id="input-file"
            type="file"
            accept="image/*"
            onChange={_uploadImage}
            style={{ display: 'none' }}
          />
        </Box>
      </Container>
    </div>
  );
};

export default MainImage;
