/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert2';
import preview from '../../assets/preview.png';
import TextLine from '../../components/common/TextLine';
import TextBox from '../../components/common/TextBox';
import Dropdown from '../../components/common/Dropdown';
import { storage } from '../../firebaseInstance';
import { ref, uploadBytes } from 'firebase/storage';

const Container = styled.div`
  height: 94vh;
  overflow: scroll;
  width: 100%;
  margin: 20px 20px 20px 0px;
  padding: 0px 20px 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Button = styled.button`
  display: inline-block;
  width: 100px;
  height: 40px;
  background-color: #a8a8a8;
  margin-left: 10px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border: none;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  :hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Box = styled.div`
  background-color: white;
  min-width: 300px;
  height: 330px;
  padding: 5px 20px 20px 20px;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.02);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ThumbnailContainer = styled.label`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Text = styled.div`
  font-size: 16px;
  margin: 5px 0px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
  border-radius: 20px;
  object-fit: cover;
  position: absolute;
`;

const Hover = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 20px;
  z-index: 100;
  position: absolute;
  :hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const ProductAddPage = () => {
  const navigate = useNavigate();

  const _handleChange = (e) => {
    let { value, name } = e.target;
    if (!(name === 'product_name' || name === 'description'))
      value = value.replace(/[^0-9]/g, '');
    setProduct({ ...product, [name]: value });
  };

  const _selectChange = (name, value) => {
    if (name === 'main_category') {
      getCategory(value);
    }
    setProduct({ ...product, [name]: value });
  };

  const [url, setUrl] = useState(preview);
  const [file, setFile] = useState();
  const [product, setProduct] = useState({
    main_category: '',
    sub_category: '',
    product_name: '',
    school: null,
    vip: null,
    level1: null,
    level2: null,
    level3: null,
    mall: null,
    description: '',
    img: '',
  });
  const [manufacturer, setManufacturer] = useState([]);
  const [category, setCategory] = useState([]);

  const getMenu = async () => {
    try {
      getManufacturer();
    } catch (e) {
      console.log(e, 'getProduct');
    }
  };

  const getManufacturer = async () => {
    try {
      const { data } = await axios.get('/admin/manufacturer');
      let arr = [];
      data.map((d) => {
        arr.push(d.main_category);
      });
      setManufacturer(arr);
      getCategory(arr[0]);
    } catch (e) {
      console.log(e);
    }
  };

  const getCategory = async (manufacturer) => {
    try {
      const { data } = await axios.get(`/admin/category/${manufacturer}`);
      console.log(data);
      let arr = [];
      data.map((d) => {
        arr.push(d.sub_category);
      });
      setCategory(arr);
    } catch (e) {
      console.log(e);
    }
  };

  const _uploadImage = async (e) => {
    const imgFile = e.target.files[0];
    const url = URL.createObjectURL(imgFile);
    setUrl(url);
    setFile(imgFile);
  };

  const createProduct = async () => {
    if (
      url !== preview &&
      product.main_category &&
      product.sub_category &&
      product.product_name &&
      product.vip &&
      product.level1 &&
      product.level2 &&
      product.level3 &&
      product.school &&
      product.mall &&
      product.description
    ) {
      swal
        .fire({
          text: '제품을 등록하시겠습니까?',
          icon: 'warning',
          iconColor: '#d33',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#a8a8a8',
          confirmButtonText: '확인',
          cancelButtonText: '취소',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              const res = await axios.post(
                `/admin/products/productlist`,
                product,
              );
              if (res.status === 200) {
                const imageRef = ref(storage, `/image/${res.data._id}`);
                uploadBytes(imageRef, file);
                try {
                  const resImg = await axios.get(
                    `/admin/products/image/${res.data._id}`,
                  );
                  if (resImg.status === 200) {
                    swal.fire({
                      text: '제품 등록이 완료되었습니다.',
                      icon: 'success',
                      showConfirmButton: false,
                      timer: 1000,
                    });
                    navigate('/product/list');
                  }
                } catch (e) {
                  swal.fire({
                    width: 400,
                    text: '오류가 발생했습니다.',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1000,
                  });
                }
              }
            } catch (e) {
              swal.fire({
                width: 400,
                text: '오류가 발생했습니다.',
                icon: 'error',
                showConfirmButton: false,
                timer: 1000,
              });
            }
          }
        });
    } else {
      swal.fire({
        width: 400,
        text: '입력하지 않은 값이 있습니다.',
        icon: 'error',
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  useEffect(() => {
    getMenu();
  }, []);

  return (
    <div style={{ width: '100%' }}>
      {product && (
        <Container>
          <RowWrapper>
            <RowWrapper style={{ alignItems: 'flex-end' }}>
              <h2>제품 등록</h2>
              <h4 style={{ marginLeft: '10px', color: '#a8a8a8' }}>
                입력 후 등록 버튼을 꼭 눌러주세요.
              </h4>
            </RowWrapper>
            <Button onClick={() => createProduct()}>등록</Button>
          </RowWrapper>
          <RowWrapper>
            <Box>
              <h3>썸네일</h3>
              <ThumbnailContainer for="input-file">
                <Hover></Hover>
                <Image id="image" src={url} />
              </ThumbnailContainer>
              <input
                id="input-file"
                type="file"
                accept="image/*"
                onChange={_uploadImage}
                style={{ display: 'none' }}
              />
            </Box>
            <Box style={{ marginLeft: '20px', width: '100%' }}>
              <h3>가격 정보</h3>
              <RowWrapper>
                <ColumnWrapper
                  style={{ padding: '5px 0px', marginRight: '20px' }}
                >
                  <Text>level1</Text>
                  <Text>level2</Text>
                  <Text>level3</Text>
                  <Text>school</Text>
                  <Text>mall</Text>
                  <Text>vip</Text>
                </ColumnWrapper>
                <ColumnWrapper>
                  <RowWrapper>
                    <TextLine
                      value={product.level1}
                      name="level1"
                      onChange={_handleChange}
                    />
                    <Text style={{ marginLeft: '5px' }}>₩</Text>
                  </RowWrapper>
                  <RowWrapper>
                    <TextLine
                      value={product.level2}
                      name="level2"
                      onChange={_handleChange}
                    />
                    <Text style={{ marginLeft: '5px' }}>₩</Text>
                  </RowWrapper>
                  <RowWrapper>
                    <TextLine
                      value={product.level3}
                      name="level3"
                      onChange={_handleChange}
                    />
                    <Text style={{ marginLeft: '5px' }}>₩</Text>
                  </RowWrapper>
                  <RowWrapper>
                    <TextLine
                      value={product.school}
                      name="school"
                      onChange={_handleChange}
                    />
                    <Text style={{ marginLeft: '5px' }}>₩</Text>
                  </RowWrapper>
                  <RowWrapper>
                    <TextLine
                      value={product.mall}
                      name="mall"
                      onChange={_handleChange}
                    />
                    <Text style={{ marginLeft: '5px' }}>₩</Text>
                  </RowWrapper>
                  <RowWrapper>
                    <TextLine
                      value={product.vip}
                      name="vip"
                      onChange={_handleChange}
                    />
                    <Text style={{ marginLeft: '5px' }}>₩</Text>
                  </RowWrapper>
                </ColumnWrapper>
              </RowWrapper>
            </Box>
            <Box style={{ marginLeft: '20px', width: '100%' }}>
              <h3>제품 정보</h3>
              <div style={{ width: '100%' }}>
                <Text>제조사</Text>
                <Dropdown
                  value={product.main_category || '선택하세요.'}
                  name="main_category"
                  data={manufacturer}
                  _selectChange={_selectChange}
                />
                <Text style={{ marginTop: '10px' }}>카테고리</Text>
                <Dropdown
                  value={product.sub_category || '선택하세요.'}
                  name="sub_category"
                  data={category}
                  _selectChange={_selectChange}
                />
                <Text style={{ marginTop: '10px' }}>제품명</Text>
                <TextBox
                  height={60}
                  value={product.product_name}
                  name="product_name"
                  onChange={_handleChange}
                ></TextBox>
              </div>
            </Box>
          </RowWrapper>
          <Box style={{ marginTop: '20px', width: '100%' }}>
            <h3>상세 설명</h3>
            <TextBox
              height={150}
              value={product.description}
              name="description"
              onChange={_handleChange}
            ></TextBox>
          </Box>
        </Container>
      )}
    </div>
  );
};
export default ProductAddPage;
