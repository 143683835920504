/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert2';
import TextLine from '../../components/common/TextLine';
import TextBox from '../../components/common/TextBox';
import Dropdown from '../../components/common/Dropdown';
import { storage } from '../../firebaseInstance';
import { ref, uploadBytes } from 'firebase/storage';
import Loading from '../../components/common/Loading';

const Container = styled.div`
  height: 94vh;
  overflow: scroll;
  width: 100%;
  margin: 20px 20px 20px 0px;
  padding: 0px 30px 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ThumbnailContainer = styled.label`
  position: relative;
  width: 100%;
  height: 270px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Button = styled.button`
  display: inline-block;
  width: 100px;
  height: 40px;
  background-color: #a8a8a8;
  margin-left: 10px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border: none;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  :hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
`;

const Hover = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 20px;
  z-index: 100;
  position: absolute;
  :hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Box = styled.div`
  background-color: white;
  min-width: 300px;
  height: 330px;
  padding: 5px 20px 25px 20px;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.02);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Text = styled.div`
  font-size: 16px;
  margin: 5px 0px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
  border-radius: 20px;
  object-fit: cover;
`;

const ProductDetailPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const _handleChange = (e) => {
    let { value, name } = e.target;
    if (!(name === 'product_name' || name === 'description'))
      value = value.replace(/[^0-9]/g, '');
    setProduct({ ...product, [name]: value });
  };

  const _selectChange = (name, value) => {
    if (name === 'main_category') {
      getCategory(value);
    }
    setProduct({ ...product, [name]: value });
  };

  const [product, setProduct] = useState();
  const [manufacturer, setManufacturer] = useState([]);
  const [category, setCategory] = useState([]);
  const [url, setUrl] = useState();
  const [file, setFile] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getProduct = async (id) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(`/admin/products/productlist/${id}`);
      setProduct(data);
      setUrl(data.img);
      getManufacturer();
      getCategory(data.main_category);
      setIsLoading(false);
    } catch (e) {
      console.log(e, 'getProduct');
    }
  };

  const getManufacturer = async () => {
    try {
      const { data } = await axios.get('/admin/manufacturer');
      let arr = [];
      data.map((d) => {
        arr.push(d.main_category);
      });
      setManufacturer(arr);
    } catch (e) {
      console.log(e);
    }
  };

  const getCategory = async (manufacturer) => {
    try {
      const { data } = await axios.get(`/admin/category/${manufacturer}`);
      let arr = [];
      data.map((d) => {
        arr.push(d.sub_category);
      });
      setCategory(arr);
    } catch (e) {
      console.log(e);
    }
  };

  const _uploadImage = (e) => {
    const imgFile = e.target.files[0];
    const url = URL.createObjectURL(imgFile);
    setUrl(url);
    setFile(imgFile);
  };

  const updateProduct = async (id) => {
    try {
      const imageRef = ref(storage, `/image/${id}`);
      if (!file) {
        const res = await axios.put(`/admin/products/update/${id}`, product);
        if (res.status === 200)
          swal.fire({
            position: 'top-end',
            width: 400,
            text: '제품 정보가 수정되었습니다.',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000,
          });
      } else {
        await uploadBytes(imageRef, file).then(async () => {
          const res = await axios.put(`/admin/products/update/${id}`, product);
          if (res.status === 200)
            swal.fire({
              position: 'top-end',
              width: 400,
              text: '제품 정보가 수정되었습니다.',
              icon: 'success',
              showConfirmButton: false,
              timer: 1000,
            });
        });
      }
    } catch (e) {
      swal.fire({
        position: 'top-end',
        width: 400,
        text: '오류가 발생했습니다.',
        icon: 'error',
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const deleteProduct = () => {
    swal
      .fire({
        text: '정말 제품을 삭제하시겠습니까?',
        icon: 'warning',
        iconColor: '#d33',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#a8a8a8',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const res = await axios.delete(`/admin/products/delete/${id}`);
            if (res.status === 200)
              swal.fire({
                text: '제품 삭제가 완료되었습니다.',
                icon: 'success',
                showConfirmButton: false,
                timer: 1000,
              });
            navigate('/product/list');
          } catch (e) {
            swal.fire({
              position: 'top-end',
              width: 400,
              text: '오류가 발생했습니다.',
              icon: 'error',
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
  };

  useEffect(() => {
    getProduct(id);
  }, [id]);

  return (
    <div style={{ width: '100%' }}>
      {product && (
        <Container>
          <RowWrapper>
            <RowWrapper style={{ alignItems: 'flex-end' }}>
              <h2>제품 상세 보기</h2>
              <h4 style={{ marginLeft: '10px', color: '#a8a8a8' }}>
                수정이 완료되면 저장 버튼을 꼭 눌러주세요.
              </h4>
            </RowWrapper>
            <Button
              style={{ backgroundColor: '#cfcfcf' }}
              onClick={() => deleteProduct()}
            >
              삭제
            </Button>
            <Button onClick={() => updateProduct(id)}>저장</Button>
          </RowWrapper>
          <RowWrapper>
            <Box>
              <h3>썸네일</h3>
              <ThumbnailContainer for="input-file">
                <Hover></Hover>
                <Image id="image" src={url} />
              </ThumbnailContainer>
              <input
                id="input-file"
                type="file"
                accept="image/*"
                onChange={_uploadImage}
                style={{ display: 'none' }}
              />
            </Box>
            <Box style={{ marginLeft: '20px', width: '100%' }}>
              <h3>가격 정보</h3>
              <RowWrapper>
                <ColumnWrapper
                  style={{ padding: '5px 0px', marginRight: '20px' }}
                >
                  <Text>level1</Text>
                  <Text>level2</Text>
                  <Text>level3</Text>
                  <Text>school</Text>
                  <Text>mall</Text>
                  <Text>vip</Text>
                </ColumnWrapper>
                <ColumnWrapper>
                  <RowWrapper>
                    <TextLine
                      value={product.level1}
                      name="level1"
                      onChange={_handleChange}
                    />
                    <Text style={{ marginLeft: '5px' }}>₩</Text>
                  </RowWrapper>
                  <RowWrapper>
                    <TextLine
                      value={product.level2}
                      name="level2"
                      onChange={_handleChange}
                    />
                    <Text style={{ marginLeft: '5px' }}>₩</Text>
                  </RowWrapper>
                  <RowWrapper>
                    <TextLine
                      value={product.level3}
                      name="level3"
                      onChange={_handleChange}
                    />
                    <Text style={{ marginLeft: '5px' }}>₩</Text>
                  </RowWrapper>
                  <RowWrapper>
                    <TextLine
                      value={product.school}
                      name="school"
                      onChange={_handleChange}
                    />
                    <Text style={{ marginLeft: '5px' }}>₩</Text>
                  </RowWrapper>
                  <RowWrapper>
                    <TextLine
                      value={product.mall}
                      name="mall"
                      onChange={_handleChange}
                    />
                    <Text style={{ marginLeft: '5px' }}>₩</Text>
                  </RowWrapper>
                  <RowWrapper>
                    <TextLine
                      value={product.vip}
                      name="vip"
                      onChange={_handleChange}
                    />
                    <Text style={{ marginLeft: '5px' }}>₩</Text>
                  </RowWrapper>
                </ColumnWrapper>
              </RowWrapper>
            </Box>
            <Box style={{ marginLeft: '20px', width: '100%' }}>
              <h3>제품 정보</h3>
              <div style={{ width: '100%' }}>
                <Text>제조사</Text>
                <Dropdown
                  value={product.main_category}
                  name="main_category"
                  data={manufacturer}
                  _selectChange={_selectChange}
                />
                <Text style={{ marginTop: '10px' }}>카테고리</Text>
                <Dropdown
                  value={product.sub_category}
                  name="sub_category"
                  data={category}
                  _selectChange={_selectChange}
                />
                <Text style={{ marginTop: '10px' }}>제품명</Text>
                <TextBox
                  height={60}
                  value={product.product_name}
                  name="product_name"
                  onChange={_handleChange}
                ></TextBox>
              </div>
            </Box>
          </RowWrapper>
          <Box style={{ marginTop: '20px', width: '100%' }}>
            <h3>상세 설명</h3>
            <TextBox
              height={150}
              value={product.description}
              name="description"
              onChange={_handleChange}
            ></TextBox>
          </Box>
        </Container>
      )}
      {isLoading && <Loading />}
    </div>
  );
};

export default ProductDetailPage;
