import React, { useState } from 'react';
import styled from 'styled-components';
import { BiDownArrow } from 'react-icons/bi';

const Container = styled.div`
  width: 80px;
  position: relative;
  display: inline-block;
  text-align: center;
`;

const Clickable = styled.div`
  height: 30px;
  width: 80px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    font-weight: 500;
  }
`;

const List = styled.div`
  border: 1px solid #eeeeee;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: white;
  position: absolute;
  width: 80px;
  z-index: 1;
`;

const Level = styled.div`
  margin: 15px 0;
  cursor: pointer;
  :hover {
    font-weight: 500;
  }
`;

const LevelDropdown = ({ user, level, handleLevel, approved }) => {
  const levels = ['vip', 'level1', 'level2', 'level3', 'school', 'mall']
  const [open, setOpen] = useState(false);
  const _handleClick = () => {
    setOpen(!open);
  };

  const _handleSelect = (level) => {
    setOpen(!open);
    handleLevel({ level: level, user });
  }
  return (
    <Container>
      {
        approved === true ? (
          <>
            <Clickable onClick={_handleClick}>
              {level}
              <BiDownArrow size="10" style={{ marginLeft: "5px" }} />
            </Clickable>
            {
              open &&
              <List>
                {levels.map((level) => <Level key={level} onClick={() => _handleSelect(level)}>{level}</Level>)}
              </List>
            }
          </>
        ) : (
          <Clickable style={{ cursor: 'default' }}>
            {level}
            <BiDownArrow size="10" style={{ marginLeft: "5px" }} />
          </Clickable>
        )
      }

    </Container>
  );
};

export default LevelDropdown;
