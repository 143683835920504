import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 5px;
  border: solid #a8a8a8 1px;
  border-radius: 10px;
  margin: 5px 0px;
`;

const Container = styled.input`
  width: 100%;
  font-size: 14px;
  outline: none;
  border: none;
  ::placeholder {
    color: #a8a8a8;
  }
`;

const TextLine = ({ height, type, name, value, placeholder, onChange }) => {
  return (
    <Wrapper>
      <Container style={{ height: height }} type={type} name={name} value={value} onChange={onChange} placeholder={placeholder || "가격을 입력해주세요."} />
    </Wrapper>
  );
};

export default TextLine;