import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import Sidebar from './components/common/Sidebar';
import {
  LoginPage,
  UserListPage,
  UserLevelPage,
  ProductListPage,
  ProductDetailPage,
  ProductAddPage,
  ProductCategoryPage,
  OrderListPage,
  CategoryDetailPage,
  MainImage,
  NoticeImage,
  TotalProductListPage,
} from './pages/index';

const Main = styled.div`
  display: flex;
  flex-direction: row;
`;

const App = () => {
  const navigate = useNavigate();

  const user = localStorage.getItem('user');

  const _handleLogin = () => {
    navigate('/order/list');
  };
  const _handleLogout = () => {
    localStorage.removeItem('user');
    window.location.reload();
  };
  return (
    <>
      {user ? (
        <Main>
          <Sidebar onLogout={_handleLogout} />
          <Routes>
            <Route path="/user/list" element={<UserListPage />} />
            <Route path="/user/level" element={<UserLevelPage />} />
            <Route path="/product/list" element={<ProductListPage />} />
            <Route
              path="/product/list/detail/:id"
              element={<ProductDetailPage />}
            />
            <Route path="/product/add" element={<ProductAddPage />} />
            <Route path="/product/category" element={<ProductCategoryPage />} />
            <Route path="/product/total" element={<TotalProductListPage />} />
            <Route path="/order/list" element={<OrderListPage />} />
            <Route
              path="/product/category/:name"
              element={<CategoryDetailPage />}
            />
            <Route path="/admin/main" element={<MainImage />} />
            <Route path="/admin/notice" element={<NoticeImage />} />

          </Routes>
        </Main>
      ) : (
        <LoginPage onLogin={_handleLogin} />
      )}
    </>
  );
};

export default App;
