/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Table from '../../components/common/Table';
import axios from 'axios';
import styled from 'styled-components';
import Loading from '../../components/common/Loading';

const Container = styled.div`
  background-color: white;
  height: 94vh;
  overflow: scroll;
  width: 100%;
  margin: 20px 20px 20px 0px;
  padding: 20px;
  box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const TotalProductListPage = () => {
  const [products, setProducts] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const getProducts = async () => {
    try {
      setIsLoading(true);
      let { data: res } = await axios.get('/admin/products/pricelist');
      res.map((r) => {
        r.vip = r.vip.toLocaleString('ko-KR');
        r.school = r.school.toLocaleString('ko-KR');
        r.level1 = r.level1.toLocaleString('ko-KR');
        r.level2 = r.level2.toLocaleString('ko-KR');
        r.level3 = r.level3.toLocaleString('ko-KR');
        r.mall = r.mall.toLocaleString('ko-KR');
        return r;
      });
      setIsLoading(false);
      setProducts(res);
    } catch (e) {
      console.log(e, 'getList');
    }
  };

  const titles = ['제조사', '카테고리', '제품명', 'vip', 'mall', 'level1', 'level2', 'level3', 'school'];

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Container>
      {products && (
        <Table caption="전체 제품 가격표" titles={titles} data={products} />
      )}
      {isLoading && <Loading />}
    </Container>
  );
};

export default TotalProductListPage;
