import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Button = styled.button`
  display: inline-block;
  width: ${(props) => props.width || "60"}px;
  height: ${(props) => props.height || "30"}px;
  background-color: #EEEEEE;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border: none;
  color: ${(props) => props.color || "#000"};
  font-size: 15px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  :hover{
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
`;

const SmallButton = ({ text, url }) => {
  const navigate = useNavigate();
  return <Button onClick={() => navigate(url)}>{text}</Button>;
};

export default SmallButton;