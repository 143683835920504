import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 5px;
  border-bottom: solid black 3px;
  margin-bottom: 50px;
`;

const Container = styled.input`
  background-color: transparent;
  width: 400px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 20px;
  outline: none;
  border: none;
  ::placeholder {
    color: #8a8a8a;
  }
`;

const Input = ({ type, name, value, placeholder, onChange }) => {
  return (
    <Wrapper>
      <Container type={type} name={name} value={value} onChange={onChange} placeholder={placeholder || "내용을 입력해주세요."} />
    </Wrapper>
  );
};

export default Input;