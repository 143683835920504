import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #000;
  color: #000;
  border-radius: 10rem;
  font-size: 20px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  width: ${(props) => props.width || "300"}px;
  height: ${(props) => props.height || "50"}px;
`;

const Button = (props) => <StyledButton {...props} />;

export default Button;