import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAJXjO2gnP-7zVakC02ZIiH2kPLL8O6l0A',
  authDomain: 'supertrack-dbb55.firebaseapp.com',
  projectId: 'supertrack-dbb55',
  storageBucket: 'supertrack-dbb55.appspot.com',
  messagingSenderId: '1002060068132',
  appId: '1:1002060068132:web:22d69927d9018840f86fb1',
  measurementId: 'G-JVBXYVVLJ0',
};
const firebaseApp = initializeApp(firebaseConfig);

// Get a reference to the storage service, which is used to create references in your storage bucket
export const storage = getStorage(firebaseApp);
export const auth = getAuth();
