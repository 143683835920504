import React, { useState } from 'react';
import styled from 'styled-components';
import { BiDownArrow } from 'react-icons/bi';

const Container = styled.div`
  position: relative;
  display: inline-block;
  text-align: center;
  width: 100%;
  font-size: 14px;
  border: solid #a8a8a8 1px;
  border-radius: 10px;
`;

const Clickable = styled.div`
  width: 100%;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  :hover {
    font-weight: 500;
  }
`;

const List = styled.div`
  border: 1px solid #eeeeee;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: white;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const Level = styled.div`
  margin: 15px 0;
  cursor: pointer;
  :hover {
    font-weight: 500;
  }
`;

const Dropdown = ({ _selectChange, name, data, value }) => {
  const [open, setOpen] = useState(false);
  const _handleClick = () => {
    setOpen(!open);
  };

  const [dropdown, setDropdown] = useState(value);
  const _handleSelect = (value) => {
    setOpen(!open);
    setDropdown(value);
  };
  return (
    <Container>
      <Clickable onClick={_handleClick}>
        {dropdown}
        <BiDownArrow size="10" />
      </Clickable>
      {
        open &&
        <List>
          {data.map((value) => <Level onClick={() => { _selectChange(name, value); _handleSelect(value); }}>{value}</Level>)}
        </List>
      }
    </Container>
  );
};

export default Dropdown;
